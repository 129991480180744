<!--
 * @Author: lzh
 * @Date: 2022-07-06 14:34:25
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 10:37:59
 * @Description: file content
-->
<template>
  <div class="edit-map-box">
    <div class="cell-b">
      <h3>項-標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="item in language"
          :key="item.code"
          v-model="configs[`${item.code=='HK'?'':item.code}title`]"
          :placeholder="`請輸入標題-${item.name}`"
          @input="setValue(`${item.code=='HK'?'':item.code}title`)"
        ></el-input>
      </div>

      <h3>項-維度配置</h3>
      <el-input
        v-model="configs.lat"
        placeholder="請輸入維度"
        @input="setValue('lat')"
      ></el-input>
      <h3>項-經度配置</h3>
      <el-input
        v-model="configs.lng"
        placeholder="請輸入經度"
        @input="setValue('lng')"
      ></el-input>
    </div>
    <!-- <selfCell style="margin-top: 10px" title="選擇切換圖片">
      <selfUpload
        v-model="configs.img"
        type="default"
        @change="setValue('img')"
      />
    </selfCell> -->
  </div>
</template>

<script>
export default {
  name: 'edit-map',
  props: {
    configs: {
      default () {
        return {
          title: '',
          img: '',
          lat: '',
          lng: '',
        };
      },
    },
  },
  data () {
    return {
      language: []
    }
  },
  created () {
    this.language = this.$storage.get('language')
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>

<style lang="less" scoped></style>
